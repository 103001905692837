<template>
  <div class="litegraph__canvas">
    <div class="litegraph__breadcrumbs pa-4">{{ breadcrumbs }}</div>
    <div class="litegraph__loading-indicator pa-4 d-flex" v-if="loadingDataForWidget">
      <v-progress-circular indeterminate />
      <div>Loading data for widget...</div>
    </div>
    <save-dialog v-model="isSaveDialogOpen" />
    <!-- Custom JSON Dialog -->
    <v-dialog height="200" width="400" v-model="jsonDialogOpen">
      <v-card>
        <v-card-text
          @drop.prevent="addFile"
          @dragover.prevent="isDropping = true"
          class="pb-0 pt-8"
        >
          <v-file-input
            v-model="file"
            label="JSON"
            outlined
            dense
            accept="json"
            :error-messages="fileError"
            @change="addFile"
          ></v-file-input>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="resetJsonDialog">Close</v-btn>
          <v-btn @click="loadJson" :disabled="(!file && !customJson) || fileError" color="primary"
            >Load</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <canvas id="litegraph-canvas" class="litegraph__canvas" />
    <!-- Area for custom litegraph controls -->
    <div class="litegraph-controls__container d-flex justify-center align-center">
      <v-btn @click="jsonDialogOpen = true">
        Import JSON
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.litegraph__canvas {
  height: 100%;
  width: calc(100%);
  .litegraph__breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
  }
  .litegraph__loading-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
  }
}
.litegraph-controls__container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
}
</style>
<script>
import { LGraph, LGraphCanvas } from '@sentry-health/sentry-litegraph';
import Vue from 'vue';

export default {
  name: 'LitegraphViewer',
  data() {
    return {
      canvas: null,
      customJson: null,
      fileError: null,
      file: null,
      graph: null,
      jsonDialogOpen: false,
      loadingDataForWidget: false,
    };
  },
  components: {
    SaveDialog: () => import('./SaveDialog.vue'),
  },
  computed: {
    activeLitegraphVersion() {
      return this.$store.state.activeWorkflowVersion;
    },
    breadcrumbs() {
      if (!this.isEdited) return 'Creating new workflow';
      return `${this.parentName} > ${this.activeLitegraphVersion.displayName}`;
    },
    isEdited() {
      return !!this.activeLitegraphVersion.id;
    },
    isSaveDialogOpen() {
      return this.$store.state.dialogs.saveDialog;
    },
    parentName() {
      return this.$store.state.workflows.find((w) => w.id === this.activeLitegraphVersion?.parent)
        ?.displayName;
    },
  },
  methods: {
    async initTenantLocations(node) {
      const { providerId } = this.$store.state.user;
      const result = await this.$apollo.query({
        query: await import('../graphql/Query/GetAllLocations.gql'),
        variables: {
          providerId,
        },
        fetchPolicy: 'no-cache',
      });
      const locations = {};
      result.data.getAllLocations.forEach((l, index) => {
        locations[`location${index}`] = l.name;
      });
      // Must manually change the value of the widget when the widget selection is changed
      const onLocationChanged = (selectedIndex, lgraphcanvas, locationNode) => {
        const [locationWidget] = locationNode.widgets;

        const { values: locationOptions } = locationWidget.options;
        locationWidget.value = Object.keys(locationOptions)[selectedIndex];
        // eslint-disable-next-line no-param-reassign
        locationNode.properties.selectedLocationId = result.data.getAllLocations[selectedIndex]?.id;
      };
      // addWidget() function on the node allows us to add a custom select
      node.addWidget('Combo', 'Location', null, onLocationChanged, {
        values: locations,
        property: 'selectedLocationId',
      });
    },
    async initTenantTags(node) {
      const { providerId } = this.$store.state.user;
      const result = await this.$apollo.query({
        query: await import('../graphql/Query/GetAllTags.gql'),
        variables: {
          providerId,
        },
        fetchPolicy: 'no-cache',
      });
      const tags = {};
      result.data.getNoteTags.forEach((l, index) => {
        tags[`tag${index}`] = l.value;
      });
      // Must manually change the value of the widget when the widget selection is changed
      const onTagChanged = (selectedIndex, lgraphcanvas, tagNode) => {
        const [tagWidget] = tagNode.widgets;
        const { values: tagOptions } = tagWidget.options;
        tagWidget.value = Object.keys(tagOptions)[selectedIndex];
        // eslint-disable-next-line no-param-reassign
        tagNode.properties.selectedTagId = result.data.getNoteTags[selectedIndex]?.id;
      };
      // addWidget() function on the node allows us to add a custom select
      node.addWidget('Combo', 'Tag', null, onTagChanged, {
        values: tags,
        property: 'selectedTagId',
      });
    },
    addFile(e) {
      this.fileError = null;
      try {
        let droppedFile;
        if (e?.dataTransfer?.files) {
          [droppedFile] = e?.dataTransfer?.files;
        } else {
          droppedFile = e;
        }
        if (!droppedFile) return;
        if (droppedFile.type !== 'application/json') {
          throw new Error('File must be JSON');
        }

        this.file = droppedFile;
        const fr = new FileReader();

        fr.onload = (contents) => {
          this.customJson = contents.target.result;
        };

        fr.readAsText(droppedFile);
      } catch (error) {
        this.fileError = error.message;
      }
    },
    loadJson() {
      this.loadLitegraph(this.customJson);
      this.resetJsonDialog();
    },
    // eslint-disable-next-line no-unused-vars
    loadLitegraph(schema) {
      const graph = new LGraph();

      // Litegraph has a built-in event that emits when a node is added.
      // Event gives us access to the node that is added.
      graph.onNodeAdded = async (node) => {
        // When loadingDataForWidget is true, I display a loading indicator in bottom left corner of litegraph viewer
        this.loadingDataForWidget = true;
        // Add your custom init function here for each node title
        // Can execute different functions based on the name of the node.
        const initFuncs = {
          // initTenantLocations is its own function defined in the "methods" section of this Vue file
          'Get Tenant Locations': this.initTenantLocations,
          'Get Tenant Tags': this.initTenantTags,
        };
        try {
          // Each init func will receive the node as an argument
          if (initFuncs[node.title]) initFuncs[node.title](node);
        } catch (e) {
          this.$store.dispatch('setNotification', {
            color: 'error',
            timeout: 15000,
            text: `Error loading data for widget "${node.title}": ${e}`,
          });
        }
        this.loadingDataForWidget = false;
      };
      this.graph = graph;

      Vue.prototype.$graph = graph;

      this.canvas = new LGraphCanvas('#litegraph-canvas', graph, { autoresize: true });
      try {
        if (schema) {
          graph.load(schema);
        } else {
          graph.start();
        }
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error loading litegraph: ${e}`,
        });
      }
    },
    resetJsonDialog() {
      this.customJson = null;
      this.file = null;
      this.fileError = null;
      this.jsonDialogOpen = false;
    },
  },
  watch: {
    activeLitegraphVersion: {
      immediate: true,
      deep: true,
      handler(newActiveVersion, oldActiveVersion = {}) {
        const { liteGraph: newLiteGraph, id: newId } = newActiveVersion || {};
        const { id: oldId } = oldActiveVersion;
        if (oldId === newId && newId !== null && newId !== undefined) return;
        this.$nextTick(() => {
          this.loadLitegraph(newLiteGraph);
        });
      },
    },
  },
};
</script>
