<template>
  <v-container style="height: calc(100vh - 64px)" class="pa-0" fluid>
    <workflow-editor v-model="isWorkflowDialogOpen" @refresh="refreshWorkflows" />
    <select-provider v-if="!providerId" v-model="isSelectProviderOpen" />
    <v-row style="height: 100%" no-gutters>
      <v-progress-linear :indeterminate="loading" />
      <v-col cols="9" class="litegraph__column">
        <litegraph-viewer />
      </v-col>
      <v-col cols="3" class="workflow-selection__column">
        <workflow-list :workflows="workflowsSorted" @refresh="getWorkflows">
          <template v-slot:headerButton="{ selected }">
            <v-menu
              min-width="290px"
              offset-y
              bottom
              transition="scroll-y-transition"
              v-model="sortMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="selected !== null && selected !== undefined"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item-group v-model="sortSelection">
                  <v-list-item @click="sort = 'desc'">
                    <v-list-item-content>
                      Sort by updated at (desc)
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="sort = 'asc'">
                    <v-list-item-content>
                      Sort by updated at (asc)
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="sort = 'alpha'">
                    <v-list-item-content>
                      Sort alphabetically
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </workflow-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.litegraph__column {
  height: 100%;
}
.workflow-selection__column {
  height: 100%;
}
.large-icon {
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #eee;
  height: 125px !important;
  width: 125px !important;
}
</style>
<script>
import LitegraphViewer from '../components/LitegraphViewer.vue';
import SelectProvider from '../components/SelectProvider.vue';
import WorkflowList from '../components/WorkflowList.vue';
import GET_WORKFLOWS from '../graphql/Query/GetWorkflows.gql';

export default {
  components: {
    LitegraphViewer,
    SelectProvider,
    WorkflowEditor: () => import('../components/WorkflowEditor.vue'),
    WorkflowList,
  },
  name: 'MainPage',
  data() {
    return {
      workflows: [],
      sort: 'desc',
      sortSelection: null,
      sortMenu: false,
    };
  },
  computed: {
    isSelectProviderOpen() {
      return !this.providerId;
    },
    isWorkflowDialogOpen() {
      return this.$store.state.dialogs.workflowEditor.open;
    },
    loading() {
      return this.$apollo.queries.workflows.loading;
    },
    providerId() {
      return this.$store.state.user.providerId;
    },
    shouldRefreshWorkflows() {
      return !!this.$store.state.refreshWorkflows;
    },
    workflowsSorted() {
      if (!this.workflows) return [];
      return [...this.workflows].sort((a, b) => {
        if (this.sort === 'desc') return b.updatedAt - a.updatedAt;
        if (this.sort === 'asc') return a.updatedAt - b.updatedAt;

        // eslint-disable-next-line no-nested-ternary
        return a.displayName.toLowerCase() < b.displayName.toLowerCase()
          ? -1
          : b.displayName.toLowerCase() < a.displayName.toLowerCase()
          ? 1
          : 0;
      });
    },
  },
  methods: {
    getWorkflows() {
      this.$apollo.queries.workflows.refresh();
    },
    refreshWorkflows() {
      this.workflows = [];
      this.getWorkflows();
    },
  },
  created() {
    this.$apollo.queries.workflows.setOptions({
      fetchPolicy: 'no-cache',
    });
  },
  apollo: {
    workflows: {
      query: GET_WORKFLOWS,
      variables() {
        const { providerId } = this;
        return { providerId };
      },
      update(data) {
        this.$store.commit('set', { workflows: data.readWorkflows });
        return data.readWorkflows;
      },
      error(error) {
        if (!this.providerId) return;
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `GET WORKFLOWS ERROR: ${error}`,
        });
      },
    },
  },
  watch: {
    shouldRefreshWorkflows: {
      async handler(val) {
        if (!val) return;
        await this.getWorkflows();
        this.$store.commit('set', { refreshWorkflows: false });
      },
    },
  },
};
</script>
