<template>
  <v-dialog v-model="value" @click:outside="$emit('close')" persistent width="512">
    <v-card rounded class="mx-auto">
      <v-card-title>
        Select a Provider
      </v-card-title>
      <v-card-text> A provider must be selected to use Workflows</v-card-text>
      <v-card-text>
        <v-autocomplete
          :items="providers"
          label="Select a provider"
          :loading="loading"
          :search-input.sync="searchQuery"
          item-text="company"
          return-object
          v-model="selectedProvider"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="selectProvider" color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ADMIN } from '../constants/roles';
import GET_ALL_PROVIDERS from '../graphql/Query/GetAllProviders.gql';

export default {
  name: 'SelectProvider',
  props: {
    value: Boolean,
  },
  data() {
    return {
      ADMIN,
      loading: true,
      providers: [],
      searchQuery: '',
      selectedProvider: null,
    };
  },
  async mounted() {
    const { data } = await this.$apollo.query({
      query: GET_ALL_PROVIDERS,
      fetchPolicy: 'no-cache',
    });
    this.providers = data.getAllProviders;
    this.selectedProvider = this.providers.find(
      ({ id }) => id === this.$store.state.user.providerId
    );
    this.loading = false;
  },
  methods: {
    selectProvider() {
      const { id: providerId } = this.selectedProvider;
      this.$store.commit('saveUser', { providerId });
    },
  },
};
</script>
