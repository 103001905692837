<template>
  <v-list-group
    two-line
    v-model="expanded"
    no-action
    append-icon=""
    active-class="workflow-item__active"
  >
    <v-progress-linear :indeterminate="loading" />
    <template v-slot:prependIcon>
      <v-icon>{{ expanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
    </template>
    <template v-slot:activator>
      <v-list-item-content class="workflow-item__content">
        <v-list-item-title>{{ workflow.displayName }}</v-list-item-title>
        <v-list-item-subtitle
          >{{ formatUnixTime(workflow.updatedAt)
          }}<span class="ml-1">(Last update)</span></v-list-item-subtitle
        >
      </v-list-item-content>
      <v-list-item-action v-if="expanded" class="d-flex flex-row">
        <v-btn icon dark @click="editItem"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-menu
          min-width="290px"
          offset-y
          bottom
          transition="scroll-y-transition"
          v-model="sortMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="sortSelection">
              <v-list-item @click="sort = 'desc'">
                <v-list-item-content>
                  Sort by time (desc)
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="sort = 'asc'">
                <v-list-item-content>
                  Sort by time (asc)
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="sort = 'alpha'">
                <v-list-item-content>
                  Sort alphabetically
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </template>
    <v-list-item-group class="ml-2 workflow-versions__list" style="background-color: white;">
      <!-- Create new Version -->
      <v-list-item
        two-line
        class="workflow-list-version__item"
        active-class="create-version__button--active"
        @click="openSaveDialog"
        v-if="(!versions || !versions.length) && !loading"
      >
        <v-list-item-icon class="mb-3 mt-5">
          <v-icon color="primary">mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Publish new version</v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon><v-icon>mdi-save</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      <workflow-list-version-item
        v-for="version in versionsSorted"
        :key="`version-${version.id}`"
        :item="{ ...version, parent: workflow.id }"
        :isActiveVersion="version.id === workflow.activeVersion"
        :disabled="settingActiveVersion"
        @createNew="openSaveDialog"
        @loading="setLoading"
      />
    </v-list-item-group>
  </v-list-group>
</template>

<script>
import { formatUnixTime } from '../utils/time';
import GET_VERSIONS from '../graphql/Query/GetWorkflowVersions.gql';
import WorkflowListVersionItem from './WorkflowListVersionItem.vue';

export default {
  components: { WorkflowListVersionItem },
  name: 'WorkflowListItem',
  data() {
    return {
      expanded: false,
      loading: false,
      settingActiveVersion: false,
      sort: 'desc',
      sortSelection: null,
      sortMenu: false,
      versions: [],
    };
  },
  props: {
    workflow: Object,
  },
  computed: {
    shouldRefresh() {
      return this.$store.state.refreshWorkflowVersions;
    },
    versionsSorted() {
      return [...this.versions].sort((a, b) => {
        if (this.sort === 'desc') return b.updatedAt - a.updatedAt;
        if (this.sort === 'asc') return a.updatedAt - b.updatedAt;

        // eslint-disable-next-line no-nested-ternary
        return a.displayName.toLowerCase() < b.displayName.toLowerCase()
          ? -1
          : b.displayName.toLowerCase() < a.displayName.toLowerCase()
          ? 1
          : 0;
      });
    },
  },
  methods: {
    editItem(e) {
      e.stopPropagation();
      this.$store.commit('toggleWorkflowEditor', { open: true, item: this.workflow });
    },
    formatUnixTime,
    openSaveDialog() {
      this.$store.commit('toggleDialog', { dialog: 'saveDialog', open: true });
    },
    setLoading(bool) {
      this.loading = bool;
    },
  },
  watch: {
    expanded(val) {
      if (!val) return;
      this.$apollo.queries.versions.refresh();
    },
    async shouldRefresh(val) {
      if (!val || !this.expanded) return;
      await this.$apollo.queries.versions.refresh();
      this.$store.commit('set', { refreshWorkflowVersions: false });
    },
  },
  apollo: {
    versions: {
      query: GET_VERSIONS,
      manual: true,
      variables() {
        const { providerId } = this.$store.state.user;
        const { id: workflowId } = this.workflow;
        return {
          providerId,
          workflowId,
        };
      },
      result({ data, loading }) {
        this.loading = loading;
        this.versions = data?.readWorkflowVersions?.sort((a, b) => b.updatedAt - a.updatedAt) || [];
      },
      skip() {
        return !this.expanded;
      },
    },
  },
  created() {
    this.$apollo.queries.versions.stop();
  },
  mounted() {
    this.$apollo.queries.versions.setOptions({
      fetchPolicy: 'cache-and-network',
    });
  },
};
</script>
