<template>
  <div class="workflow-list__container">
    <div class="workflow-list__title d-flex">
      <v-card-title class="d-flex justify-space-between flex-nowrap" style="width: 100%">
        <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          Workflows
        </div>
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="openWorkflowEditor" v-on="on" v-bind="attrs"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <span>
              Create new workflow
            </span>
          </v-tooltip>
          <v-btn icon @click="$emit('refresh')" :disabled="refreshing"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >

          <slot name="headerButton" :selected="selectedWorkflow" /></div
      ></v-card-title>
    </div>
    <v-divider />
    <v-list class="py-0 workflow-list__inner">
      <v-list-item-group v-model="selectedWorkflow" v-if="!reRender && workflows.length">
        <workflow-list-item v-for="workflow in workflows" :key="workflow.id" :workflow="workflow" />
        <!-- v-show="
            selectedWorkflow === null ||
              selectedWorkflow === undefined ||
              selectedWorkflow === index
          " -->
      </v-list-item-group>
      <div style="height: 100%" class="d-flex justify-center align-center flex-column" v-else>
        <v-btn icon large class="large-icon" @click="openWorkflowEditor"
          ><v-icon x-large>mdi-plus</v-icon></v-btn
        >
        <div class="mt-5">No workflows found.</div>
      </div>
    </v-list>
  </div>
</template>
<style lang="scss">
.workflow-list__container {
  height: 100%;
  width: 100%;
  .workflow-list__inner {
    height: calc(100% - 69px);
    overflow: auto;
    .v-list-group--active {
      height: 100%;
      .v-list-group__items {
        height: calc(100% - 62px);
        .workflow-versions__list {
          overflow: auto;
        }
      }
    }
  }
  .create-version__button--active {
    color: black !important;
    &:before {
      opacity: 0;
    }
  }
  .workflow-item-loading__container {
    height: 100%;
    width: 100%;
  }
  .workflow-list-version__item {
    &.v-list-item {
      &:before {
        &:not(:hover) {
          opacity: 0 !important;
        }
      }
    }
    &.v-list-item--active {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    &.active {
      &.v-list-item {
        background-color: rgba(43, 138, 255, 0.2);
        color: var(--v-primary-base) !important;
        &:hover {
          background-color: rgba(43, 138, 255, 0.3);
        }
      }
    }
  }
  .workflow-items__list {
    background-color: var(--v-primary-base);
  }
  .workflow-item__active {
    background-color: var(--v-primary-base);
    color: white;
    &:before {
      opacity: 1;
    }
    .workflow-item__content {
      .v-list-item__subtitle {
        color: inherit !important;
      }
    }
  }
}
</style>
<script>
import WorkflowListItem from './WorkflowListItem.vue';

export default {
  components: { WorkflowListItem },
  name: 'WorkflowList',

  data() {
    return {
      newWorkflowName: null,
      newWorkflowDialogOpen: false,
      reRender: false,
      selectedWorkflow: null,
      sort: 'desc',
    };
  },
  props: {
    workflows: Array,
  },
  computed: {
    refreshing() {
      return this.$store.state.refreshWorkflows || this.$store.state.refreshWorkflowVersions;
    },
    selectedWorkflowName() {
      return this.workflows[this.selectedWorkflow]?.displayName;
    },
  },
  methods: {
    findAndSelectActiveWorkflowVersion() {
      const activeWorkflowId =
        this.$store.state.activeWorkflowVersion?.parent || this.$store.state.activeWorkflow?.id;
      if (!activeWorkflowId) return;
      const activeWorkflowIndex = this.workflows.findIndex((w) => w.id === activeWorkflowId);
      if (activeWorkflowIndex !== -1) {
        this.selectedWorkflow = activeWorkflowIndex;
      }
    },
    openWorkflowEditor() {
      this.$store.commit('toggleWorkflowEditor', { open: true });
    },
  },
  mounted() {
    this.findAndSelectActiveWorkflowVersion();
  },
  watch: {
    selectedWorkflow(val) {
      if (typeof !val === 'number' || val === undefined || val === null) {
        this.$store.commit('setActiveWorkflow', {});
      } else {
        const workflow = this.workflows[val];
        this.$store.commit('setActiveWorkflow', workflow);
      }
    },
    workflows: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        this.reRender = true;
        this.$nextTick(() => {
          this.reRender = false;
          if (this.$store.state.activeWorkflow?.id) this.findAndSelectActiveWorkflowVersion();
        });
      },
    },
  },
};
</script>
