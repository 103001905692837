<template>
  <v-list-item
    two-line
    :class="{ active: isActive }"
    class="workflow-list-version__item"
    @click="setActiveWorkflowVersion"
    :disabled="disabled"
  >
    <v-list-item-icon v-if="isActiveVersion">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-icon color="success" size="18">mdi-checkbox-marked-circle-outline</v-icon>
          </div>
        </template>
        <span>
          Active version
        </span>
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="font-italic flex-nowrap d-flex"
        ><div
          v-if="!editingName"
          class="flex-grow-0 flex-shrink-1 pr-1"
          style="min-width: 0; overflow: hidden; text-overflow: ellipsis;"
        >
          {{ editedDisplayName }}
        </div>
        <div v-else>
          <v-text-field
            hide-details
            dense
            type="text"
            width="100"
            v-model="editedDisplayName"
            @keydown="onEnter"
            @blur="onBlur"
            ref="displayName"
          />
        </div>
        <div class="flex-shrink-0">
          <v-btn
            icon
            x-small
            @click="editDisplayName"
            v-if="isActive && !editingName"
            :disabled="disabled"
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
        </div></v-list-item-title
      >
      <v-list-item-subtitle
        >{{ formatUnixTime(item.updatedAt) }}
        <span class="mr-1">(Last Update)</span></v-list-item-subtitle
      >
    </v-list-item-content>
    <v-list-item-action class="flex-row">
      <v-menu offset-y bottom transition="scroll-y-transition" v-model="menuOpen" v-if="isActive">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon :disabled="disabled"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="exportToJson">
              <v-list-item-icon>
                <v-icon>mdi-file-export</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Export to JSON</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="updateVersion">
              <v-list-item-icon>
                <v-icon>mdi-content-save</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Save version</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="createNewVersion">
              <v-list-item-icon>
                <v-icon>mdi-content-save-move</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Save as new version</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setAsActiveVersion">
              <v-list-item-icon>
                <v-icon :color="isActiveVersion ? 'error' : 'success'">mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    isActiveVersion ? 'Unset as active version' : 'Set as active version'
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!-- <v-tooltip top v-if="isActive">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon @click="updateVersion" :disabled="disabled"
            ><v-icon>mdi-content-save</v-icon></v-btn
          >
        </template>
        <span>
          Save Version
        </span>
      </v-tooltip>
      <v-tooltip top v-if="isActive">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon @click="createNewVersion" :disabled="disabled"
            ><v-icon>mdi-content-save-move</v-icon></v-btn
          >
        </template>
        <span>
          Save as new version
        </span>
      </v-tooltip>
      <v-tooltip top v-if="isActive">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn icon @click="setAsActiveVersion" :disabled="isActiveVersion || disabled"
              ><v-icon>mdi-power</v-icon></v-btn
            >
          </div>
        </template>
        <span>
          {{
            isActiveVersion
              ? 'This workflow is already the active version'
              : 'Set as active version'
          }}
        </span>
      </v-tooltip> -->
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { formatUnixTime } from '../utils/time';

export default {
  name: 'WorkflowListVersionItem',
  data() {
    return {
      editingName: false,
      editedDisplayName: null,
      loading: false,
      menuOpen: false,
    };
  },
  props: {
    item: Object,
    isActiveVersion: Boolean,
  },
  methods: {
    createNewVersion() {
      this.$emit('createNew');
    },
    formatUnixTime,
    editDisplayName(e) {
      e.stopPropagation();
      this.editingName = true;
      this.$nextTick(() => {
        this.$refs.displayName.focus();
      });
    },
    exportToJson() {
      const { displayName, liteGraph } = this.item || {};
      if (!liteGraph) return;
      const a = document.createElement('a');
      const file = new Blob([liteGraph], { type: 'application/json' });
      a.href = URL.createObjectURL(file);
      a.download = `${displayName}`;
      a.click();
    },
    onBlur() {
      this.editingName = false;
    },
    onEnter(e) {
      e.stopPropagation();
      if (e.code !== 'Enter') return;
      this.editingName = false;
      this.updateVersion(e, false);
    },
    setActiveWorkflowVersion() {
      this.$store.commit('setActiveWorkflowVersion', this.item);
    },
    setAsActiveVersion(e) {
      this.menuOpen = false;
      e.stopPropagation();
      if (this.isActiveVersion) {
        this.setActiveVersion({ id: null });
      } else {
        this.setActiveVersion(this.item);
      }
    },
    async setActiveVersion(newVersion) {
      this.$emit('loading', true);
      this.loading = true;
      const { providerId } = this.$store.state.user;
      const { displayName } = this.$store.state.activeWorkflow;

      try {
        await this.$apollo.mutate({
          mutation: await import('../graphql/Mutation/UpdateWorkflow.gql'),
          variables: {
            providerId,
            workflow: {
              displayName,
            },
            workflowId: this.$store.state.activeWorkflow.id,
            activeVersion: newVersion.id,
          },
        });

        this.$store.commit('set', { refreshWorkflows: true });
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error setting active version: ${e}`,
        });
      }
      this.loading = false;
      this.$emit('loading', true);
    },
    async updateVersion(e, notifyOnSuccess = true) {
      this.menuOpen = false;
      if (e) e.stopPropagation();

      this.loading = true;
      const { providerId } = this.$store.state.user;
      try {
        await this.$apollo.mutate({
          mutation: await import('../graphql/Mutation/UpdateWorkflowVersion.gql'),
          variables: {
            providerId,
            workflowId: this.item.parent,
            workflowVersionId: this.item.id,
            version: {
              displayName: this.editedDisplayName,
              liteGraph: JSON.stringify(this.$graph.serialize()),
            },
          },
        });
        if (notifyOnSuccess) {
          this.$store.dispatch('setNotification', {
            color: 'success',
            timeout: 15000,
            text: `Workflow version "${this.editedDisplayName}" saved`,
          });
        }

        this.$store.commit('set', { refreshWorkflowVersions: true });
      } catch (err) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          timeout: 15000,
          text: `Error saving workflow version: ${err}`,
        });
      }

      this.loading = false;
    },
  },
  computed: {
    isActive() {
      return this.$store.state.activeWorkflowVersion.id === this.item.id;
    },
    disabled() {
      return this.loading || this.refreshing;
    },
    refreshing() {
      return this.$store.state.refreshWorkflows || this.$store.state.refreshWorkflowVersions;
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler({ displayName }) {
        this.editedDisplayName = displayName;
      },
    },
  },
};
</script>
